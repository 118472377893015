
.project {
  &::after {
    content:'';
    display:block;
    height:2px;
    background-image:linear-gradient(90deg,transparent,$real_black,transparent);
    margin:4em auto;
    width:88%;
  }
  @media only screen and (min-width:$medium) {
    width:47%;
  }

  &__date {
    color:$grey;
  }

  &__preview {
    margin:1em auto;
    max-width:800px;
    background:$white;
    border-radius:4px;
    flex-flow:column;
  }

  &__image {
    width:100%;
  }

  &__caption {
    width:100%;
    // margin-top:-1%;
    // padding-top:2%;
    background:$real_white;
  }

  &__intro {
    text-align:center;
    margin-bottom:0.5em;
  }

  &__icon {
    float:right;
    font-weight:600;
    height:2em;
    border-bottom:1px solid $real_black;
    border-right:1px solid $real_black;
    padding-left:1em;
    &::after {
      content:'+';
      width:1.5em;
      text-align:center;
      transition:transform $middle;
    }
  }

  &__expandIcon--expanded::after, &__closeIcon::after {
    transform:rotate(-135deg);
  }

  &__closeIcon {
    font-size:1.11em;
    margin-bottom:1px;
    overflow:hidden;
  }

  &__details {
    font-size:0.9em;
    clear:both;
    overflow-y:hidden;
    max-height:0;
    counter-reset:section;
    transition:max-height 0.88s;
  }

}


/* -------  details section  ------- */
.details {

  &__section {
    margin-bottom:3em;
    &:first-child {
      margin-top:2em;
    }
    .details__subheader:first-of-type {
      margin-top:1.5em;
    }
  }

  &__header {
    margin-bottom:1em;
    font-weight:600;
    &::before {
      display:inline;
      border-bottom:1px solid $real_black;
      margin-right:0.4em;
      counter-increment:section;
      content:counter(section,lower-roman)'. ';
    }
  }
  &__subheader {
    font-size:.96969em;
    font-weight:600;
    font-style:italic;
    margin:2em 0 0.5em;
  }
  &__content {
    margin-bottom:1em;
  }
  &__list {
    font-size:.9em;
    padding-left:2em;
    margin-bottom:2em;
    list-style-type:circle;
    &Item {
      margin:0.5em 0;
    }
  }
  &__tldr {
    margin-bottom:2em;
    &::before {
      content:'';
      display:block;
      height:2px;
      background-image:linear-gradient(90deg,transparent,$real_black,transparent);
      margin:0 auto 3em;
      width:60%;
    }
  }
}


// .project:not(:last-child)::after { content:'';display:block;background:$real_black;width:66.6%;height:2px;margin:3em auto }
.project:last-child { margin-bottom:6em }
.preview { display:flex;align-items:center }
.preview .x { display:none;width:52px;height:52px;position:absolute;top:0;right:0;cursor:pointer }
// .preview figure { text-align:center;margin:1em 0 }
.preview img { cursor:pointer;display:block;margin:0 auto }
.preview figcaption { color:$grey;font-size:0.9em; }
.hippify img { border:1px solid $real_black;padding:2% 4% }
.klopro img { border:1px solid $real_black;background:$real_white;padding:2% 4% 0 }
.fullscreen img { border:0 }
