
body {
  color:$real_black;
  background:$real_white;
  font:400 16px/1.45 $normal;
  text-rendering:optimizeLegibility;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  @media only screen and (max-width:$xxsmall) { font-size:14px }
  @media only screen and (min-width:$xsmall)  { font-size:17px }
  @media only screen and (min-width:$small)   { font-size:18px }
  @media only screen and (min-width:$medium)  { font-size:19px }
  @media only screen and (min-width:$large)   { font-size:20px }
  @media only screen and (min-width:$xlarge)  { font-size:21px }
  @media only screen and (min-width:$xxlarge) { font-size:22px }
}

#app {
  width:100%;
  height:100%;
  display:flex;
  justify-content:flex-end;
  flex-direction:column;
}

.inner__container, .social {
  opacity:0;
  transition:opacity 0.44s;
}
footer {
  transform:translateY(125%);
  transition:transform $cubic 0.44s;
}

#app.ready {
  .inner__container, .social {
    opacity:1;
  }
  .greeting::first-line {
    font-family:$cursive;
  }
  footer {
    transform:translateY(0);
  }
}

img[data-src] {
  opacity:0;
  height:0;
  // background:$white;
  padding-top:62.5%;
}

img {
  opacity:1;
  transition:opacity 0.44s ease-in 1.22s;
}
