
/* -------  sections  ------- */
.sections {
  display:flex;
  flex-direction:column;
  @media only screen and (min-width:$medium) {
    flex-flow:wrap row;
    justify-content:space-between;
    font-size:0.8em;
  }
}

/* -------  icon  ------- */
.icon {
  cursor:pointer;
}

.transparent .line { background:transparent }
