
/* -------  resets  ------- */
* { margin:0;padding:0 }
html, body { height:100%;box-sizing:border-box }
*, *::before, *::after { box-sizing:inherit }
img { max-width:100% }
a { color:inherit;text-decoration:none }
ul, menu { list-style-type:none }
h1,h2,h3,h4,h5,h6 { font-weight:inherit }
strong { font-weight:600 }
input { border:0;background:inherit;font-family:inherit }
input:focus { outline:0 }
figure { text-align:center }
figcaption { font-size:0.9em }
table, hr { border:0 }
nav { letter-spacing:0.5px;font-weight:500 }
sup { font-size:66% }
code { padding:0.2em 0;font-size:75%;background-color:rgba(27,31,35,0.05);border-radius:3px;font-family:$code;white-space:nowrap }
code::before, code::after { letter-spacing:-0.2em;content:'\00a0' }
::-webkit-scrollbar { width:0;height:0;background:transparent }
