
.intro {
  text-align:center;
  max-width:900px;
  margin:0 auto;
  @media only screen and (min-width:$medium) {
    width:80%;
  }
}

.about__info {
  margin:2em 0 4em;
  &_heading {
    margin-left:4%;
  }
  @media only screen and (min-width:$medium) {
    width:48%;
  }
}




.outer { font-size:0.9em;padding-left:2em;margin:2em 0 }
.inner { font-size:0.9em;padding-left:1.5em;display:flex;flex-wrap:wrap }
.outer li { margin:1em 0;position:relative;font-weight:600 }
.inner li { margin:0.5em 0;width:50%;padding-right:20px;font-weight:initial }
.outer li::before { content:'+';position:absolute;left:-16px;font-weight:600 }
.inner li::before { content:'-';left:-12px }
.about__info-personal .outer li { font-weight:initial }
.about__info-personal .inner li { width:100% }
/* svmogies */
.svmogi { height:100%;width:20px;display:inline-block;position:absolute;background: transparent center center / contain no-repeat }
.ua   { display:none }
.sfg  { background-image:url('/assets/img/about/san-francisco-giants.svg');margin-left:-1px }
.sf49 { background-image:url('/assets/img/about/san-francisco-49ers.svg');margin-left:3px }
.gsw  { background-image:url('/assets/img/about/golden-state-warriors.svg');margin-left:1px }

.hide { opacity:0;transition:opacity 1s }
.show { opacity:1;transition:opacity 1s }
