
header {
  flex:0;
  display:flex;
  min-height:0;
  position:relative;
  transition:flex 0s 0.66s;
}

.trigger {
  width:104px;
  height:104px;
  z-index:$top;
  position:absolute;
  right:0;
  @media only screen and (min-width:$max_width) {
    margin-right:calc((100% - #{$max_width}) / 2);
  }
  &__container {
    width:50%;
    height:50%;
    position:relative;
    cursor:pointer;
  }
  &__line {
    display:block;
    height:2px;
    width:24px;
    position:absolute;
    top:25px;left:14px;
  }
  &--menu {
    background:$real_white;
  }
  &__line {
    background:$real_black;
    transition:transform 0.44s $cubic;
    &--top {
      transform:translateY(-4px);
    }
    &--bottom {
      transform:translateY(4px);
    }
  }
}

.menu {
  line-height:1;
  font-size:1.2em;
  flex:1;
  flex-flow:column;
  @media only screen and (min-width:$small) {
    font-size:1.4em;
  }

}

.menu__list {
  flex:1;
  max-height:400px;
  overflow:hidden;
  flex-direction:column;
  @media only screen and (min-width:$small) {
    flex-flow:row wrap;
    max-height:600px;
    width:75%;
    height:62.5%;
  }
  @media only screen and (min-width:$large) {
    width:62.5%;
    height:75%;
  }
}

.menu__item {
  flex:1;
  opacity:0;
  width:100%;
  margin:16% 0;
  max-height:120px;
  flex-direction:column;
  transition:opacity 0.22s ease-in;

  @for $i from 3 through 1 {
    &:nth-of-type(#{$i}) {
      transition-delay:(#{(3 - $i) * 0.11}s);
    }
  }

  @media only screen and (min-width:$small) {
    margin:0;
    height:100%;
    flex-basis:50%;
    max-height:160px;
    &:first-child {
      flex-basis:100%;
    }
  }

}

.menu__link {
  flex:1;
  font-variant:small-caps;
  display:inline-flex;
  flex-direction:column;
  justify-content:center;
  padding:0 1em;

  @media only screen and (min-width:$small) {
    flex:initial;
  }
  &::before {
    content:'';
    display:block;
    margin-bottom:0.16em;
    font-size:2em;
    height:1em;
    background:center center / contain no-repeat;
  }
  &--landing::before {
    background-image:url('/icons/map-signs.svg')
  }
  &--about::before {
    background-image:url('/icons/soccer-ball.svg')
  }
  &--projects::before {
    background-image:url('/icons/floppy-disk.svg')
  }
  &::after {
    content:'';
    display:block;
    width:0;height:2px;
    margin:0 auto;
    background:$black;
    opacity:0;
    transform:translateY(-3.2em);
    transition:width $slow,opacity $average,transform $average;
  }
  &:hover::after {
    width:100%;
    opacity:1;
    transform:translateY(0)
  }
}

.menu-open {
  flex:1;
  transition:flex 0s 0.22s;

  .trigger__line {
    &--top {
      transform:rotate(135deg);
    }
    &--bottom {
      transform:rotate(45deg);
    }
  }

  .menu__item {
    opacity:1;
    transition-duration:0.42s;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        transition-delay:(#{(($i * 0.11) + 0.11) + .2}s);
      }
    }
  }

}
