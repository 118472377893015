

/* -------  colors  ------- */
/* ------------------------ */
$real_white: #ffffff;
$white:      #f5f5f5;
$grey:       #616161;
$black:      #070707;
$real_black: #000000;
$purple:     #30049e;


/* ---  media queries  ---- */
/* ------------------------ */
$xxsmall:     20em;
$xsmall:      25em;
$small:       40em;
$medium:      55em;
$large:       70em;
$xlarge:      85em;
$xxlarge:    100em;


/* -------  sizing  ------- */
/* ------------------------ */
$mountains:    72px;
$max_width:  1600px;

/* -------  layers  ------- */
/* ------------------------ */
$bottom:     0;
$top:       99;

/* -------  timing  ------- */
/* ------------------------ */
$instant:      0s;
$quick:     0.22s;
$average:   0.33s;
$middle:    0.44s;
$slow:      0.66s;
$cubic:     cubic-bezier(0.25,0.46,0.45,0.94);


/* -------  fonts   ------- */
/* ------------------------ */
$normal:    'Avenir Next',Avenir,'Segoe UI',Roboto,'Helvetica Neue',sans-serif;
$code:      'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace;
$cursive:   'Yellowtail',cursive;
