
footer {
  flex:2;
  width:100%;
  z-index:$top;
  display:flex;
  background:$purple url('/mountains.svg') -1px -1px / 100px $mountains repeat-x;
  padding-top:$mountains;
  margin-top:-$mountains;
  box-shadow:0 -1.2em 1.2em 1.2em $real_white;
}

.social {
  width:80%;
  max-width:$max_width;
  margin:0 auto;
  display:flex;
  align-items:center;
}

.social__list {
  width:100%;
  max-width:250px;
  margin:0 auto;
  @media only screen and (min-width:$medium) {
    margin-right:0;
  }
  @media only screen and (min-width:$xlarge) {
    max-width:299px;
  }
}

.social__link {
  color:$real_white;
  font-size:0.96em;
  font-weight:600;
  display:block;
  padding-top:2px;
  &::after {
    content:'';
    display:block;
    height:2px;
    width:2px;
    margin:0 auto;
    background:$real_white;
    opacity:0;
    transform:translateY(-1.6em);
    transition:width $slow,opacity $average,transform $average;
  }
  &:hover::after {
    width:100%;
    opacity:1;
    transform:translateY(-2px);
  }
}
