
.flexy {
  display:flex;
  align-items:center;
  justify-content:center;
}

.spacey {
  display:flex;
  align-items:center;
  justify-content:space-between;
}
