
.inner__container {
  flex:8;
  display:flex;
  overflow:auto;
  flex-direction:column;
  padding-bottom:$mountains;
  z-index:$bottom;
}

main {
  flex:1;
  opacity:1;
  overflow-y:scroll;
  -webkit-overflow-scrolling:touch;
  transition:flex 0s 0.66s,opacity 0.22s ease-in 0.66s;
  @media only screen and (min-width:$max_width) {
    padding:0 calc((100% - #{$max_width}) / 2);
  }
}

.menu-open + main {
  flex:0;
  opacity:0;
  transition:flex 0s 0.22s,opacity 0.22s ease-out 0s;
}

.page {
  width:100%;
  padding:16vh 4% 0;
  h1 {
    font-size:2.827em;
    font-family:$cursive;
  }
  h2 {
    font-size:1.9191em;
    font-family:monospace;
  }
  h3 {
    font-size:1.414em;
    font-family:monospace;
  }
  h4 {
    font-family:monospace;
    font-size:1.0505em;
  }
  a {
    border-bottom:1px solid $real_black
  }

  &__arrow {
    float:right;
    margin:-2em 1em 4em auto;
    &::before {
      content:'';
      height:1em;
      width:1em;
      font-size:2em;
      background:url('icons/arrows-up.svg') center center / contain;
    }
  }
 }
