

/* -------  base  ------- */
@import 'base/variables';
@import 'base/resets';
@import 'base/app';
@import 'base/elements';
@import 'base/utils';

/* -------  partials  ------- */
@import 'partials/header';
@import 'partials/main';
@import 'partials/footer';

/* -------  views  ------- */
@import 'views/landing';
@import 'views/about';
@import 'views/projects';
@import 'views/404';
